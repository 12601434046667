import { css } from "aphrodite";
import { List, Map } from "immutable";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";

import { HashLink } from "components/Common/HashLink";
import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import entityLoader from "components/Entities/EntityLoader";

import AuthorityBadge from "../../Podcast/AuthorityBadgeAsync";
import EntityImage from "../EntityImage";
import EntityLink from "../EntityLink";

import podcastActions from "actions/podcast";
import { AUTHORITY_BADGE_HEIGHT } from "constants/podcast";
import { selectEntity, selectEntityLoading } from "selectors/entity";
import { selectSpecificPodcast } from "selectors/podcast";
import { toPascalCase } from "utils/case";
import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getUserlistItemUrl from "utils/entity/getUserlistItemUrl";
import generateTransition from "utils/generateTransition";
import slugify from "utils/misc/slugify";
import { getCreatorUrl } from "utils/url/creatorUrls";

import useLayoutEffect from "hooks/useLayoutEffect";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const panelStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // overflow: "hidden",
  width: "50%",
  textAlign: "center",
  marginBottom: "2rem",

  [ScreenSizes.lgAndAbove]: {
    maxWidth: "none",
    marginBottom: "0.5rem",
    width: "32.3333%",
    // height: "15rem",
    ":nth-child(3)": {
      marginRight: 0,
    },
  },
  [ScreenSizes.xlAndAbove]: {
    marginRight: "2%",
    width: "23.5%",
    marginTop: "2rem",
    marginBottom: 0,
    minHeight: "17.653rem",
    ":nth-child(1)": {
      marginTop: 0,
    },
    ":nth-child(2)": {
      marginTop: 0,
    },
    ":nth-child(3)": {
      marginTop: 0,
    },
    ":nth-child(4)": {
      marginTop: 0,
    },
    ":nth-child(4n + 3)": {
      marginRight: "2%",
    },
    ":nth-child(4n + 4)": {
      marginRight: 0,
    },
  },
};

const baseStyles = {
  creatorViewAppearancePanel: panelStyles,
  loadingOverlay: {
    ...panelStyles,
    minHeight: "8rem",
  },
  imageContainer: {
    position: "relative",
    maxWidth: "12rem",
    width: "80%",
    marginBottom: "0.75rem",
    [ScreenSizes.smAndAbove]: {
      width: "100%",
    },
  },
  appearancePodcastName: {
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    color: colours.bodyText,
    width: "100%",
    marginBottom: ".5rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.75rem",
    },
  },
  appearanceSmallText: {
    ...gStyles.fontSemiBold,
    fontSize: "0.875rem",
    color: "#617796",
    width: "100%",
    marginBottom: ".5rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.688rem",
    },
  },
  roleLink: {
    // TODO: Share with CreatorItem.js
    ...gStyles.textEllipsis,
    ...gStyles.avalonBold,
    background: colours.roles.default,
    display: "inline-block",
    padding: "0.1em 1em 0.1em",
    borderRadius: "1em",
    color: "#fff",
    fontSize: "0.9em",
    marginRight: "0.5em",
  },
  roleLinkHost: {
    background: colours.roles.host,
  },
  roleLinkGuest: {
    background: colours.roles.guest,
  },
  links: {
    ...gStyles.avalonBold,
    position: "absolute",
    flexDirection: "column",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    background: "rgba(235,235,235,0.85)", // "rgba(38,44,62,0.8)", //"rgba(34, 50, 91 ,0.7)", //
    color: "rgba(38,44,62, 0.8)", // "rgba(255,255,255, 0.8)",
    transition: generateTransition({ targets: ["opacity"] }),
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    fontSize: "0.9375em",
    ":hover .link": {
      transform: "translateY(0.5px)",
    },
    ":hover .linkInner": {
      display: "block",
    },
    [ScreenSizes.xlAndAbove]: {
      display: "flex",
      ":hover": {
        opacity: 1,
      },
    },
  },
  link: {
    width: "100%",
    height: "50%",
    flex: "0 0 50%",
    alignItems: "center",
    justifyContent: "center",
    transition: generateTransition({ targets: ["transform"] }),
    backfaceVisibility: "hidden",
    display: "flex",
    transform: "translateY(2rem)",
  },
  podcastLink: {},
  appearancesLink: {},
  linkInner: {
    background: "#fff",
    padding: "0.6em 1em",
    boxShadow: "0 0.2em 1em rgba(0,0,0,0.1)",
    borderRadius: 5,
    transition: generateTransition({ targets: ["box-shadow"] }),
    cursor: "pointer",
    display: "none",
    visibility: "hidden",
    ":hover": {
      color: "rgba(38,44,62, 1)",
      boxShadow: "0 0.3em 1em rgba(0,0,0,0.3)",
    },
    [ScreenSizes.xlAndAbove]: {
      display: "block",
      visibility: "visible",
    },
  },
};

const inCarouselStyles = {
  imageContainer: {
    maxWidth: "15rem",
  },
  creatorViewAppearancePanel: {
    width: "100%",
    maxWidth: "15rem",
    [ScreenSizes.xlAndAbove]: {
      minHeight: "17.653rem",
    },
  },
};

const imageStyles = {
  entityImage: {
    margin: "0 auto",
  },
};

const showButtonStyles = {
  links: {
    opacity: 1,
  },
  link: {
    display: "flex",
  },
  linkInner: {
    display: "flex",
    visibility: "visible",
  },
};

const CreatorViewAppearancePanel = (props) => {
  const {
    entity,
    roles,
    entity_type,
    appearance,
    creator,
    inCarouselAtSize,
    renderOnMount,
  } = props;

  const nodeRef = useRef();
  const [imageContainerWidth, setImageContainerWidth] = useState(0);
  const containerWidth = nodeRef.current ? nodeRef.current.clientWidth : null;

  const [showButtons, setShowButtons] = useState(false);

  const score = getPodcastAuthorityLevel(entity, 0);

  useLayoutEffect(() => {
    if (containerWidth !== imageContainerWidth) {
      setImageContainerWidth(containerWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth]);

  const handleClickLinks = useCallback(() => {
    setShowButtons(true);
  }, [setShowButtons]);

  const handleClick = (e) => {
    if (nodeRef.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowButtons(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const { styles } = useStyles(
    [
      baseStyles,
      inCarouselAtSize && inCarouselStyles,
      showButtons && showButtonStyles,
    ],
    props
  );

  const imageContainerStyle = useMemo(
    () => ({
      minHeight: imageContainerWidth || 172,
    }),
    [imageContainerWidth]
  );

  const commaList = useMemo(() => {
    if (roles) {
      return roles
        .filter((v) => !!v)
        .map((role) => (
          <EntityLink
            entity={creator}
            entity_type="creator"
            entityUrlPath={`appearances#${
              role && slugify(role.get("title", "host"))
            }-${entity && entity.get("id")}`}
            disablePopup
            hashLinkOffset={-20}
            key={`appearances#${role && slugify(role.get("title", "host"))}-${
              entity && entity.get("id")
            }`}
          >
            <span
              className={css(
                styles.roleLink,
                styles[`roleLink${toPascalCase(role.get("title", "host"))}`]
              )}
            >
              {role.get("title")}
            </span>
          </EntityLink>
        ));
    }

    return null;
  }, [roles, entity, creator, styles]);

  const appearancesLink = useMemo(
    () =>
      `${getCreatorUrl(creator, "appearances")}#${
        roles && slugify(roles.getIn([0, "title"], "host"))
      }-${entity && entity.get("id")}`,
    [creator, roles, entity]
  );

  return (
    <div
      className={css(styles.creatorViewAppearancePanel)}
      data-id="appearance-panel"
    >
      <span
        className={css(styles.imageContainer)}
        onClick={handleClickLinks}
        ref={(ref) => {
          nodeRef.current = ref;

          if (ref) {
            setImageContainerWidth(ref.clientWidth);
          }
        }}
        style={imageContainerStyle}
      >
        <EntityImage
          entity={entity}
          entity_type={entity_type}
          fullWidth
          size={imageContainerWidth || 200}
          disableLink
          square
          styles={imageStyles}
        />
        <span className={css(styles.links)}>
          <span
            className={`${css(
              styles.link,
              styles.podcastLink
            )} link podcastLink`}
          >
            <Link
              to={getUserlistItemUrl(entity, entity_type)}
              className={`${css(styles.linkInner)} linkInner`}
              data-id="appearance-panel-link"
            >
              View Podcast
            </Link>
          </span>
          <span
            className={`${css(
              styles.link,
              styles.appearancesLink
            )} link appearancesLink`}
          >
            <HashLink
              to={appearancesLink}
              className={`${css(styles.linkInner)} linkInner`}
            >
              View {pluralize("Credit", roles.size)}
            </HashLink>
          </span>
        </span>
      </span>
      <div className={css(styles.appearancePodcastName)}>
        <EntityLink
          entity={entity}
          entity_type={entity_type}
          showClaimedBadge
          inline
        >
          {entity.get("title")}
        </EntityLink>
        {score && (
          <LazyLoadComponent
            renderOnMount={renderOnMount}
            height={AUTHORITY_BADGE_HEIGHT}
          >
            <AuthorityBadge
              entity={entity}
              entity_type={entity_type}
              size={13}
            />
          </LazyLoadComponent>
        )}
      </div>

      <LazyLoadComponent renderOnMount={renderOnMount} height={16}>
        <div className={css(styles.appearanceSmallText)}>{commaList}</div>
        <div className={css(styles.appearanceSmallText)}>
          <EntityLink
            entity={creator}
            entity_type="creator"
            entityUrlPath={appearancesLink}
            disablePopup
            hashLinkOffset={-20}
          >
            {appearance.get("episode_count")}{" "}
            {pluralize("episode", appearance.get("episode_count"))}
          </EntityLink>
        </div>
      </LazyLoadComponent>
    </div>
  );
};

CreatorViewAppearancePanel.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  entity_type: PropTypes.string.isRequired,
  roles: PropTypes.instanceOf(List).isRequired,
  appearance: PropTypes.instanceOf(Map).isRequired,
  creator: PropTypes.instanceOf(Map),
  inCarouselAtSize: PropTypes.string,
  renderOnMount: PropTypes.bool,
};

CreatorViewAppearancePanel.defaultProps = {
  creator: null,
  inCarouselAtSize: null,
  renderOnMount: false,
};

export default compose(
  memo,
  entityLoader({
    itemName: "entity",
    loaders: podcastActions,
    execOnMount: (props) => {
      if (props.entity_type === "episode") {
        props.loadSpecificEpisode(props.id);
      } else {
        props.loadSpecificPodcast(props.id);
      }
    },
    selector: (state, props) => {
      const entity = selectEntity(state, props.id, props.entity_type);

      if (entity && props.entity_type === "episode") {
        return entity.set(
          "podcast",
          selectSpecificPodcast(state, entity.get("podcast_id"))
        );
      }

      return entity;
    },
    loadingSelector: (state, props) =>
      selectEntityLoading(state, props.id, props.entity_type),
    noLoadingOverlay: true,
    noLoadingPadding: true,
    loadingStyles: {
      noOverlay: baseStyles.loadingOverlay,
    },
  })
)(CreatorViewAppearancePanel);
