import Immutable from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import AddEntityButton from "components/Entities/Page/AddEntityButton";

import authActions from "actions/auth";
import { selectAuthLoggedIn } from "selectors/auth";
import { getCreatorEditCreditsOverview } from "utils/url/creatorUrls";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

const AddAppearancesButton = (props) => {
  const { creator } = props;

  const { startAuthFlow } = useActionCreators(authActions);
  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const addAppearanceLinkProps = useMemo(() => {
    const addCreditsTo = getCreatorEditCreditsOverview(creator);

    return isLoggedIn
      ? { to: addCreditsTo }
      : {
          onClick: () =>
            startAuthFlow(undefined, {
              entity: creator,
              entity_type: "creator",
              entityTitle: `credits for "${creator.get("name")}"`,
              entityAction: "action",
              analyticsVariables: {
                triggered_by: "creatorViewAddAppearancesButton",
              },
              forward: addCreditsTo,
            }),
        };
  }, [creator, startAuthFlow, isLoggedIn]);

  return (
    <AddEntityButton
      label="Add Credits"
      dataId="add-appearances-button"
      {...addAppearanceLinkProps}
    />
  );
};

AddAppearancesButton.propTypes = {
  creator: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default AddAppearancesButton;
