import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ListCarousel = Loadable(() => import("./ListCarousel"));

function ListCarouselAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const ListCarouselContainer = (props) => {
  return <ListCarousel {...props} fallback={<ListCarouselAsyncLoading />} />;
};

export default ListCarouselContainer;
