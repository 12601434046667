import { css } from "aphrodite";
import range from "lodash/range";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "17.806rem",
    gap: "0.938rem",

    [ScreenSizes.lgAndAbove]: {
      height: "17.653rem",
      gap: "0.986rem",
    },
  },
  creditSkeleton: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    height: "14.743rem",
    width: "8.313rem",

    [ScreenSizes.lgAndAbove]: {
      height: "17.653rem",
      width: "11.592rem",
    },
  },
};

export default function LoadingAppearanceGrid(props) {
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const DEFAULT_LIST_COUNT = mobile ? 2 : 4;
  const PLACEHOLDER_CREDITS = range(DEFAULT_LIST_COUNT);
  return (
    <div className={css(styles.container)}>
      {PLACEHOLDER_CREDITS.map((i, j) => (
        <div className={css(styles.creditSkeleton)} key={`key${j + 1}`} />
      ))}
    </div>
  );
}
