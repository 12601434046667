import getEpisodeUrl from "./getEpisodeUrl";
import getPodcastUrl from "./getPodcastUrl";

export default function getUserlistItemUrl(entity, entity_type, path) {
  if (entity_type === "episode") {
    return getEpisodeUrl(entity, { path });
  }

  return getPodcastUrl(entity, path);
}
