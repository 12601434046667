import { Map } from "immutable";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { useMemo } from "react";
import Helmet from "react-helmet-async";

import { getBaseUrl } from "constants/base";
import durationFormatter from "utils/date/durationFormatter";
import {
  getCreatorMetaDescription,
  getCreatorMetaImageUrl,
} from "utils/entity/creator";
import { generateMetaArray } from "utils/meta";
import { getCreatorUrl } from "utils/url/creatorUrls";

export const getPageTitle = (name) => `${name}'s Podcast Credits & Interviews`;

const CreatorViewAppearancesStructuredData = (props) => {
  const { creator } = props;
  const canonicalUrl = useMemo(
    () => getBaseUrl() + getCreatorUrl(creator, "appearances"),
    [creator]
  );
  const creatorMetaDescription = useMemo(
    () => getCreatorMetaDescription(creator),
    [creator]
  );
  const name = useMemo(() => creator.get("name"), [creator]);

  const episodeCountString =
    creator.get("episode_credit_count") > 0
      ? `${pluralize("episode", creator.get("episode_credit_count"), true)}`
      : "";
  const totalAirtimeString =
    creator.get("total_airtime") > 0
      ? ` totalling ${durationFormatter.humanize(
          creator.get("total_airtime") * 1000,
          { units: ["h", "m"], round: true }
        )}. `
      : ". ";
  const metaArray = useMemo(
    () =>
      generateMetaArray({
        title: name,
        url: canonicalUrl,
        image: { url: getCreatorMetaImageUrl(creator) },
        description: `${episodeCountString}${totalAirtimeString}${creatorMetaDescription}`,
      }),
    [
      canonicalUrl,
      creator,
      creatorMetaDescription,
      episodeCountString,
      name,
      totalAirtimeString,
    ]
  );
  const title = useMemo(() => getPageTitle(name), [name]);
  const links = useMemo(
    () => [{ rel: "canonical", href: canonicalUrl }],
    [canonicalUrl]
  );

  return <Helmet title={title} meta={metaArray} link={links} />;
};

CreatorViewAppearancesStructuredData.propTypes = {
  creator: PropTypes.instanceOf(Map).isRequired,
};

export default CreatorViewAppearancesStructuredData;
