import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { List, Map } from "immutable";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import ListCarousel from "components/Common/Lists/ListCarouselAsync";
import CreatorViewAppearancePanel from "components/Entities/Items/CreatorViewAppearancePanel";
import CreatorViewAppearancesStructuredData from "pages/CreatorView/Appearances/CreatorViewAppearancesStructuredData";

import LoadingAppearanceGrid from "./LoadingAppearanceGrid";

import getCreatorUrl from "utils/entity/getCreatorUrl";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  appearancePanels: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: -10,

    [ScreenSizes.mdAndAbove]: {
      justifyContent: "flex-start",
    },
    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
  moreLink: {
    ...gStyles.sectionMoreLink,
  },
  icon: {
    marginLeft: "0.75em",
  },
};

const AppearanceGrid = (props) => {
  const {
    credits,
    creator,
    loading,
    loaded,
    maxSize,
    invalidCounts,
    carouselAtSize,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const actualMaxSize = useMemo(() => {
    let actualMaxSize = maxSize
      ? Math.min(maxSize, credits.getIn([0, "count"]))
      : credits.getIn([0, "count"]);
    if (invalidCounts.length > 0) {
      while (actualMaxSize > 0 && invalidCounts.indexOf(actualMaxSize) > -1) {
        actualMaxSize = actualMaxSize - 1;
      }
    }
    return actualMaxSize;
  }, [credits, maxSize, invalidCounts]);

  const appearances = useMemo(
    () =>
      (credits && credits.hasIn([0, "by_podcast_id"])
        ? credits.getIn([0, "by_podcast_id"])
        : Map()
      ).slice(0, actualMaxSize),
    [credits, actualMaxSize]
  );

  const roles = useMemo(() => {
    const sorted = appearances.sortBy(
      (appearance) =>
        -appearance.get("by_role").reduce((highestRank, roleType) => {
          if (roleType.getIn(["role", "role_rank"]) > highestRank) {
            return roleType.getIn(["role", "role_rank"]);
          }

          return highestRank;
        }, 0)
    );

    return sorted.map((appearance) => ({
      appearance,
      roles: appearance
        .get("by_role")
        .sort((a, b) => a.get("role_rank") - b.get("role_rank"))
        .map((roleType) => roleType.get("role"))
        .slice(0, 8),
    }));
  }, [appearances]);

  const renderAppearance = (appearance) => (
    <CreatorViewAppearancePanel
      key={appearance.appearance.get("podcast_id")}
      id={appearance.appearance.get("podcast_id")}
      appearance={appearance.appearance}
      entity_type="podcast"
      roles={appearance.roles}
      creator={creator}
      inCarouselAtSize={carouselAtSize}
    />
  );

  const renderAppearances = () => {
    if (!loaded || loading) {
      return <LoadingAppearanceGrid />;
    }
    if (appearances.size > 0) {
      return (
        <ListCarousel
          items={roles}
          renderItem={renderAppearance}
          carouselAtSize={carouselAtSize}
          minCarouselItemPercentage={isMediumOrLess ? 34 : 23}
          noEndPadding
        />
      );
    }

    return (
      <div className={css(styles.noAppearances)}>
        {`We don't have any credits for ${creator.get("name")} yet.`}
      </div>
    );
  };

  return (
    <div className={css(styles.appearancePanels)}>
      <CreatorViewAppearancesStructuredData creator={creator} />
      {renderAppearances()}

      {!isMediumOrLess &&
        creator.get("podcast_credit_count") - actualMaxSize > 0 && (
          <Link
            data-id="appearances-podcasts-view-all-bottom"
            to={getCreatorUrl(creator, "appearances")}
            className={css(styles.moreLink)}
          >
            <span>{`View credits on ${creator.get(
              "podcast_credit_count"
            )} ${pluralize(
              "podcasts",
              creator.get("podcast_credit_count")
            )}`}</span>{" "}
            <span className={css(styles.icon)}>
              <FontAwesomeIcon icon={faCaretRight} />
            </span>
          </Link>
        )}
    </div>
  );
};

AppearanceGrid.propTypes = {
  podcasts: PropTypes.instanceOf(Map),
  creator: PropTypes.instanceOf(Map),
  credits: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  invalidCounts: PropTypes.array,
  maxSize: PropTypes.number,
  carouselAtSize: PropTypes.string,
};

AppearanceGrid.defaultProps = {
  credits: List(),
  podcasts: Map({}),
  creator: Map({}),
  loading: false,
  loaded: false,
  maxSize: 9999,
  invalidCounts: [],
  carouselAtSize: "medium",
};

export default AppearanceGrid;
